export default [
  {
    title: "Menu",
    route: "apps-menu",
    icon: "MenuIcon",
  },
  {
    title: "Sub menu",
    route: "apps-submenu",
    icon: "GridIcon",
  },
  {
    title: "Mega menu",
    route: "apps-megamenu",
    icon: "LayoutIcon",
  },
  {
    title: "Pages",
    route: "apps-pages",
    icon: "FileTextIcon",
  },
  {
    title: "Projects",
    route: "apps-projects",
    icon: "FlagIcon",
  },
  {
    title: "Blogs",
    route: "apps-blogs",
    icon: "BookmarkIcon",
  },
  {
    title: "Fundraiser",
    route: "apps-fundraiser",
    icon: "TrendingUpIcon",
  },
  {
    title: "Partners",
    route: "apps-partners",
    icon: "ThumbsUpIcon",
  },
  {
    title: "Priorities",
    route: "apps-priorities",
    icon: "ListIcon",
  },
  {
    title: "Footer",
    route: "apps-footer",
    icon: "InfoIcon",
  },
  {
    title: "Transactions",
    route: "apps-transactions",
    icon: "CreditCardIcon",
  },
  {
    title: "Bank Details",
    route: "apps-bank",
    icon: "CreditCardIcon",
  },
  {
    title: "User Management",
    route: "apps-users",
    icon: "UsersIcon",
  },

  // {
  //   title: "Articles",
  //   route: "apps-articles",
  //   icon: "BookmarkIcon",
  // },
  // {
  //   title: "News",
  //   route: "apps-news",
  //   icon: "TvIcon",
  // },
  // // {
  // //   title: "Stories",
  // //   route: "apps-stories",
  // //   icon: "CircleIcon",
  // // },
  // {
  //   title: "Documents",
  //   route: "apps-documents",
  //   icon: "FileIcon",
  // },
  // {
  //   title: "Hero Slider",
  //   route: "apps-slider",
  //   icon: "ImageIcon",
  // },
  // {
  //   title: "Events",
  //   route: "apps-events",
  //   icon: "FlagIcon",
  // },
  // {
  //   title: 'Products',
  //   route: 'apps-products-list',
  //   icon: 'GridIcon'
  // },
  // {
  //   title: 'Orders',
  //   route: 'apps-orders-list',
  //   icon: 'ShoppingCartIcon'
  // },
  // {
  //   title: "Vendors",
  //   route: "apps-gift",
  //   icon: "StarIcon",
  // },
  // {
  //   title: "Users",
  //   route: "apps-myuser",
  //   icon: "UserIcon",
  // },
  // {
  //   title: "Categories",
  //   route: "apps-categories",
  //   icon: "CircleIcon",
  // },
  // {
  //   title: "Sub-categories",
  //   route: "apps-subcategories",
  //   icon: "SquareIcon",
  // },
  // {
  //   title: "Change password",
  //   route: "auth-reset-password-v1",
  //   icon: "LockIcon",
  // },
  // {
  //   title: 'Vendors',
  //   route: 'apps-vendor',
  //   icon: 'UserIcon',
  // },
  // {
  //   title: 'Sub categories',
  //   route: 'apps-subcategories',
  //   icon: 'ListIcon',
  // },
  // {
  //   title: 'Zone Management',
  //   icon: 'MapPinIcon',
  //   children: [
  //     {
  //       title: 'City',
  //       route: 'apps-zonemanagement',
  //     },
  //     {
  //       title: 'Country',
  //       route: 'apps-country',
  //     },
  //     {
  //       title: 'Region',
  //       route: 'apps-regions',
  //     },
  //   ],
  // },
  // {
  //   title: 'Products',
  //   route: 'apps-all-products',
  //   icon: 'GridIcon',
  // },

  // {
  //   title: 'Vendors',
  //   route: 'apps-all-vendors',
  //   icon: 'UsersIcon',
  // },
  // {
  //   title: 'Bookings',
  //   route: 'apps-all-orders',
  //   icon: 'ShoppingBagIcon',
  // },

  // {
  //   title: 'Order status',
  //   route: 'apps-orderstatus',
  //   icon: 'TagIcon',
  // },
  // {
  //   title: 'Messages',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'User',
  //   route: 'apps-myuser',
  //   icon: 'UserIcon',
  // },

  // {
  //   title: 'Payout',
  //   route: 'apps-payout',
  //   icon: 'CreditCardIcon',
  // },

  // {
  //   title: 'Delivery Charges',
  //   route: 'apps-pricemanagement',
  //   icon: 'TruckIcon',
  // },

  // {
  //   title: 'City',
  //   route: 'apps-zonemanagement',
  //   icon: 'MapPinIcon',
  // },
  // {
  //   title: 'Country',
  //   route: 'apps-zonemanagement',
  //   icon: 'MapPinIcon',
  // },
  // {
  //   title: 'Region',
  //   route: 'apps-regions',
  //   icon: 'MapPinIcon',
  // },

  // {
  //   title: 'Seller Registration',
  //   route: null,
  //   icon: 'UserPlusIcon',
  //   action:'read',
  //   resourse:''
  // },
  // {
  //   title: 'Reports',
  //   route: 'apps-todo',
  //   icon: 'BarChart2Icon',
  // },
  // {
  //   title: 'Setting',
  //   route: '#',
  //   icon: 'SettingsIcon',
  // },
];
